* {
    box-sizing: border-box;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}

#root {
    position: relative;
    width: 100%;
    background: #F0F0F0;
}

.App {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

main {
    display: flex;
    flex-direction: row;
    padding: 0 24px;
}

.loading {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
}

header {
    padding: 24px 24px 6px 24px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #1f1f1f;
}

.matches {
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    min-height: 0;
    min-width: 0;
    margin-right: 12px;
    padding-top: 19px;
}

.total {
}

.match {
    padding: 8px 12px;
    display: flex;
    flex-direction: row;
    background: #D8D8D8;
    justify-content: space-between;
    border-radius: 2px;
    min-height: 40px;
}

.match .teams {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    color: #1F1F1F;
}

.match .bets {
    display: flex;
    flex-direction: row;
}

.match .bets .bet-button {
    background: #7B7B7B;
    width: 46px;
    height: 24px;
    margin-right: 4px;
    text-align: center;
    line-height: 22px;
    font-weight: 700;
    color: #F0F0F0;
}

.match .bets .bet {
    font-size: 11px;
}

.match .bets .bet.active .bet-button {
    background: #008528;
}

.match .bets .bet .bet-button:last {
    margin-right: 0;
}

.match .bets .bet .bb .bb-amount {
    text-align: center;
    color: #7B7B7B;
}

.disclaimer {
    color: #979797;
    font-size: 10px;
    padding-left: 24px;
    padding-right: 12px;
    text-align: center;
    padding-top:12px;
    padding-bottom: 12px;
    padding-right: 174px;
}

.background {
    position: absolute;
    right: 0;
    bottom: 0;
}

.background-triangle-gray {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 156px;
    height: 96px;
    background: #D8D8D8;
    clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
}

.background-triangle-black {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 156px;
    height: 76px;
    background: #000000;
    clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
}

.logo {
    position: absolute;
    right: 12px;
    bottom: 12px;
    width: 56px;
    height: 20px;
}

.swiper {
    padding-bottom: 18px !important;
}

.swiper .swiper-pagination {
    bottom: 0 !important;
}

.swiper .swiper-button-next, 
.swiper .swiper-button-prev {
    height: auto;
    width: auto;
    top: auto;
    bottom: -2px;
    display: inline;
    left: auto;
    left: 35%;
    z-index: 20;
}

.swiper .swiper-button-next {
    left: auto;
    right: 35%;
}

.swiper .swiper-button-next:after, 
.swiper .swiper-button-prev:after {
    font-size: 12px;
    font-weight: 800;
}

.swiper .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    margin-right: 2px !important;
    margin-left: 2px !important;
}

.total-label {
    font-size: 11px;
    color: #1F1F1F;
    opacity: 0.5;
    text-align: center;
    width: 138px;
    margin-bottom: 4px;
}

.total-odds {
    width: 138px;
    height: 34px;
    border-radius: 2px;
    background: #D8D8D8;
    color: #1F1F1F;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    line-height: 34px;
    margin-bottom: 4px;
}

.place-bets {
    width: 138px;
    height: 34px;
    border-radius: 2px;
    background: #009FE6;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    line-height: 34px;
    border: 0;
    margin-bottom: 4px;
    cursor: pointer;
}

@media screen and (max-width: 466px) {
    main {
        flex-direction: column;
    }

    .matches {
        padding-top: 12px;
    }

    .disclaimer {
        width: 50%;
        padding-right: 0;
    }

    .disclaimer .line-2 {
        display: block;
    }
    
    .total {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;
    }

    .total-label {
        width: 100%;
        margin-bottom: 6px;
    }

    .total-odds {
        flex-grow: 1;
        margin-right: 2px;
    }

    .place-bets {
        flex-grow: 1;
        margin-left: 2px;
    }
}